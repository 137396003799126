import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import ThemeProvider, { createTheme } from './theme-provider'
import typography from '../utils/typography'
import Seo from './seo'

const typographyStyles = typography.toJSON()

const customTheme = createTheme({
  typography: {
    h1: typographyStyles.h1,
    h2: typographyStyles.h2,
    h3: typographyStyles.h3,
    h4: typographyStyles.h4,
    h5: typographyStyles.h5,
    h6: typographyStyles.h6,
    subtitle1: {
      fontFamily: typographyStyles.body.fontFamily,
    },
    subtitle2: {
      fontFamily: typographyStyles.h1.fontFamily,
    },
    body1: typographyStyles.body,
    body2: {
      fontSize: '1rem',
    },
  },
});

const Main = styled.div`
  background-color: ${props => props.theme.palette.background.default};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
`

export default ({ children }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Global styles={css`
        html, body, #___gatsby, #gatsby-focus-wrapper {
          height: 100%;
        }
      `}/>
      <Seo />
      <Main>
        { children }
      </Main>
    </ThemeProvider>
  )
}