import React from 'react'
import { Typography } from '@material-ui/core'
import styled from '@emotion/styled'
import ThemeProvider, { createTheme } from './theme-provider'
import Section from './section'

const footerTheme = theme => createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    type: 'light'
  }
});

const Footer = styled.footer`
  background-color: ${props => props.theme.palette.background.default};
  flex-shrink: 0;
  text-align: center;
`

export default () => (
  <ThemeProvider theme={footerTheme}>
    <Footer>
      <Section>
        <Typography variant="body2" color="textPrimary">© {new Date().getFullYear()} BuildX. All rights reserved.</Typography>
      </Section>
    </Footer>
  </ThemeProvider>
)