
import React from 'react'
import { Link } from 'gatsby'
import appBar from '@material-ui/core/AppBar'
import toolBar from '@material-ui/core/Toolbar'
import { Container, Button } from '@material-ui/core'
import styled from '@emotion/styled'
import breakpoints from '../utils/breakpoints'
import Logo from './logo'
import MobileMenu from './mobile-menu'

const AppBar = styled(appBar)`
  background-color: ${props => props.theme.palette.background.default};
  color: #111;
`

const ToolBar = styled(toolBar)`
  justify-content: space-between;
`

const MenuList = styled.nav`
  display: none;

  ${breakpoints.md} {
    display: inherit;
  }
`

export default () => (
  <AppBar elevation={0} position="relative">
    <Container>
      <ToolBar disableGutters>
        <Logo />
        <MenuList>
          <Button component={Link} to="/">Home</Button>
          <Button component={Link} to="/services">Services</Button>
          <Button component={Link} to="/about">About</Button>
          <Button component={Link} to="/blog">Blog</Button>
          {/* <Button component={Link} to="/case-study">Case Studies</Button> */}
          <Button component={Link} to="/contact">Contact</Button>
        </MenuList>
        <MobileMenu />
      </ToolBar>
    </Container>
  </AppBar>
)
