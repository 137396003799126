import React from 'react'
import container from '@material-ui/core/Container'
import styled from '@emotion/styled'

const Container = styled(container)`
  padding-top: ${props => props.theme.spacing(props.spacing ? props.spacing : 4)}px;
  padding-bottom: ${props => props.theme.spacing(props.spacing ? props.spacing : 4)}px;
`
export default ({ children, ...props }) => (
  <Container component="section" {...props}>
    {children}
  </Container>
)