import React from 'react'
import { ThemeProvider } from 'emotion-theming';
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

export default ({ theme, children }) => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </MuiThemeProvider>
)

export const createTheme = createMuiTheme