import React from 'react'
import styled from '@emotion/styled'
import Html from './html'
import Menu from './menu'
import Footer from './footer'


const Content = styled.main`
  flex: 1 0 auto;
`

export default ({ children }) => (
  <Html>
    <Menu />
    <Content>
      {children}
    </Content>
    <Footer />
  </Html>
)