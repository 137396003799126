import React from 'react'
import { Container, Typography} from '@material-ui/core'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import breakpoints from '../utils/breakpoints'
import defaultImage from '../images/neighbourhood-no-bg.png'
import Section from './section'

const Header = styled.section`
  background-color: ${props => props.backgroundColor ? props.backgroundColor : '#85CAA6'};
  margin-bottom: ${props => props.theme.spacing(4)}px;
`

const SectionContainer = styled(Container)`
  background-image: url(${props => !!props.image ? props.image.childImageSharp.fluid.src : defaultImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${props => props.small ? '150px' : '350px'};

  ${breakpoints.md} {
    background-position: 80% 50%;
    background-size: 60%;
  }
`

const headerTextStyles = css`
  text-align: center;

  ${breakpoints.md} {
    max-width: 40%;
    text-align: left;
  }
`

const Title = styled(Typography)`
  margin: 0;
  ${headerTextStyles};
`

const Eyebrow = styled(Typography)`
  text-transform: uppercase;
  ${headerTextStyles};

  ${breakpoints.md} {
    max-width: 26%;
    text-align: left;
  }
`

export default ({ small, title, eyebrow, image, backgroundColor }) => {
  return (
    <Header backgroundColor={backgroundColor}>
      <SectionContainer small={small ? 1 : 0} image={image}>
        {eyebrow && <Eyebrow variant="subtitle1">{eyebrow}</Eyebrow>}
        {title && <Title component="h1" variant="h1">{title}</Title>}
      </SectionContainer>
    </Header>
  )
}

export const SimpleHeader = ({ title, eyebrow, maxWidth }) => (
  <Section maxWidth={maxWidth}>
    <Typography align="center" component="h1" variant="h3">{title}</Typography>
    <Typography align="center" display="block" variant="subtitle1">{eyebrow}</Typography>
  </Section>
)