import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(
    graphql`
      query SiteLogo {
        file(relativePath: {eq: "logo.png"}, sourceInstanceName: {eq: "images"}) {
          id
          childImageSharp {
            fixed(height: 45, fit: INSIDE) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <Link to="/">
      <Img
        fixed={data.file.childImageSharp.fixed}
        fadeIn={false}
        alt={data.site.siteMetadata.title}
      />
    </Link>
  )
}