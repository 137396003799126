import React, { useState } from 'react'
import { Link } from 'gatsby'
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from '@emotion/styled'
import breakpoints from '../utils/breakpoints'
import CloseIcon from '@material-ui/icons/Close'

const MobileContainer = styled.div`
  display: inherit;

  ${breakpoints.md} {
    display: none;
  }
`

const MobileMenuInner = styled.div`
  min-width: ${props => props.theme.spacing(40)}px;
`

const MobileMenuControls = styled.div`
  text-align: right;
`

const ListItemLink = styled(ListItem)`
  color: ${props => props.theme.palette.text.primary};
`

const MobileMenu = ({ closeMenu }) => (
  <MobileMenuInner>
    <MobileMenuControls>
      <IconButton onClick={closeMenu}>
        <CloseIcon />
      </IconButton>
    </MobileMenuControls>

    <List component="nav" aria-label="main mobile menu">
      <ListItemLink button component={Link} to="/">
        <ListItemText primaryTypographyProps={{variant:"h5"}}>Home</ListItemText>
      </ListItemLink>
      <ListItemLink button component={Link} to="/services">
        <ListItemText primaryTypographyProps={{variant:"h5"}}>Services</ListItemText>
      </ListItemLink>
      <ListItemLink button component={Link} to="/about">
        <ListItemText primaryTypographyProps={{variant:"h5"}}>About Me</ListItemText>
      </ListItemLink>
      <ListItemLink button component={Link} to="/blog">
        <ListItemText primaryTypographyProps={{variant:"h5"}}>Blog</ListItemText>
      </ListItemLink>
      {/* <ListItemLink button component={Link} to="/case-study">
        <ListItemText primaryTypographyProps={{variant:"h5"}}>Case Studies</ListItemText>
      </ListItemLink> */}
      <ListItemLink button component={Link} to="/contact">
        <ListItemText primaryTypographyProps={{variant:"h5"}}>Contact</ListItemText>
      </ListItemLink>
    </List>
  </MobileMenuInner>
)

export default () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <MobileContainer>
      <IconButton onClick={() => setMobileMenuOpen(true)} edge="end" aria-label="menu">
        <MenuIcon />
      </IconButton>
      <Drawer anchor='right' open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
        <MobileMenu closeMenu={() => setMobileMenuOpen(false)} />
      </Drawer>
    </MobileContainer>
  )
}